export const SECTIONS = {
  aboutMe: {
    header: 'About Me',
    description:
      "I'm a dedicated full-stack web developer with a deep passion for crafting interactive and responsive web applications. " +
      'My toolkit includes JavaScript, React, Redux, Node.js, Express, SQL Server, HTML, CSS, and Git. ' +
      "As a quick learner who's always eager to expand my skills, I thrive in collaborative environments where I can work alongside others to bring stunning applications to life. " +
      'Thank you for visiting my portfolio website—I hope it gives you a good glimpse of my capabilities!',
  },
  skills: {
    header: 'Skills',
    description:
      "I'm a passionate full-stack software developer with expertise in both frontend and backend technologies. " +
      'Skilled in creating dynamic user experiences with React and building robust server-side applications using Node.js, I thrive on turning ideas into beautifully crafted realities. ' +
      'Let’s make something amazing together!',
  },
  projects: {
    header: 'Projects',
    description:
      "Here are projects I've completed, each with its own unique story. " +
      'Dive into the demos to see my skills in action. ' +
      "I'm excited to share my journey with you! Enjoy exploring!",
  },
  contact: {
    header: "Let's Get In Touch",
    description:
      'I’m always excited to explore new opportunities and make wonderful connections! ' +
      'If you have any questions or simply want to say hello, my inbox is always open. ' +
      'I can’t wait to hear from you and will get back to you as soon as possible!',
  },
}

export const ABOUT_ME_DATA = [
  {
    title: 'Education',
    id: 'education',
    content: (
      <ul>
        <li>Northeastern University</li>
        <li>Tatung University</li>
        <li>Soochow University</li>
      </ul>
    ),
  },
  {
    title: 'Certification',
    id: 'certification',
    content: (
      <ul>
        <li>Microsoft Certified: Azure Fundamentals</li>
        <li>Software Engineering Virtual Experience</li>
      </ul>
    ),
  },
  {
    title: 'Experience',
    id: 'experience',
    content: (
      <ul>
        <li>Freelance Full-Stack Developer</li>
      </ul>
    ),
  },
]

export const projects = [
  {
    title: 'Music Player',
    description: 'React',
    dataType: 'video',
    fileUrl: 'Project-Music-Player-watermark.mp4',
  },
  {
    title: 'City View',
    description: 'React',
    dataType: 'video',
    fileUrl: 'Project-City-View-watermark.mp4',
  },
]
