import './Footer.scss'

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <h2 className="footer-header">Rita's Portfolio</h2>
        <div className="footer-title">A Full-Stack Developer</div>
      </div>
      <div className="footer-text">Hosted by AWS</div>
      <div className="footer-copyright">&copy; 2024 - By Rita Huang</div>
    </div>
  )
}
