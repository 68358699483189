import './Projects.scss'
import { useEffect, useState } from 'react'
import { projects, SECTIONS } from '../assets/helper'
import { ProjectCard } from './shared/ProjectCard'
import { usePagination } from '../hooks/usePagination'
import { getVideo } from '../api'

export const Projects = () => {
  const [currentTab, setCurrentTab] = useState(1)
  const perTabProj = 3
  const [isProjLoading, setIsProjLoading] = useState(false)
  const [newProjects, setNewProjects] = useState([])
  const projPage = usePagination(newProjects, perTabProj)
  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (projects.length > 0) {
      // Promise.all() will return an array !!!
      Promise.all(
        projects.map((project) => {
          if (project.dataType === 'video') {
            return getVideo({ key: project.fileUrl }).then((response) => {
              const link = response.data.message.fileLink
              const newProject = {
                dataType: project.dataType,
                title: project.title,
                fileUrl: link,
                description: project.description,
              }
              return newProject
            })
          } else if (project.dataType === 'image') {
            const newProject = {
              dataType: project.dataType,
              title: project.title,
              fileUrl: '',
              description: project.description,
            }
            return Promise.resolve(newProject)
          }
        }),
      )
        .then((newProjectsArray) => {
          setNewProjects(() => newProjectsArray)
          setIsProjLoading(true)
        })
        .catch((err) => console.log('Error fetching project demos'))
      // .catch((err) => console.log('Error processing projects:', err))
      // .finally(() => console.log('[FINALLY]'))
    }
  }, [])

  const handlePageChange = (page) => {
    setCurrentTab(page)
    projPage.jump(page)
  }

  const handleSelected = (selectedVideo) => {
    if (selected === selectedVideo) {
      setSelected('')
    } else {
      setSelected(selectedVideo)
    }
  }

  return (
    <section id="projects" className="projects">
      <div className="projects-container">
        <div className="projects-row">
          <div className="projects-col">
            <h2 className="section_header">{SECTIONS && SECTIONS.projects.header}</h2>
            <p className="section_description">{SECTIONS && SECTIONS.projects.description}</p>
          </div>
        </div>
        <div className="projects-nav-container">
          <ul className="projects-nav-ul">
            {Array.apply(null, Array(projPage.maxPage)).map((_, i) => (
              <li className="projects-nav-li" key={i + 1}>
                <button
                  value={i + 1}
                  className={currentTab === i + 1 ? 'active projects-nav-btn' : 'projects-nav-btn'}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {`Tab${i + 1}`}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="projects-tab-container">
          {isProjLoading && projPage?.currentData() && (
            <div className="projects-tab-row">
              {projPage
                .currentData()
                .map(
                  (project, i) =>
                    project.fileUrl.length > 0 && (
                      <ProjectCard
                        key={i}
                        {...project}
                        selected={selected}
                        onSelected={handleSelected}
                      />
                    ),
                )}
            </div>
          )}
          {!isProjLoading && (
            <div className="projects-tab-row-warn">
              Demos are currently unavailable. <br />
              Sorry for the inconvenience. Please try again later.
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
