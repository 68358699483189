import './Header.scss'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'

export const Header = ({ media }) => {
  const [activeLink, setActiveLink] = useState('home')
  const [scrolled, setScrolled] = useState(false)
  const [scrollPosition, setScrollPosition] = useState({
    home: 0,
    'about-me': 865,
    skills: 1575,
    projects: 2168,
    contact: 3300,
  })

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true)

        for (const [key, value] of Object.entries(scrollPosition)) {
          if (window.scrollY >= value) {
            setActiveLink(key)
          }
        }
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const onScrollPosition = (scrollPositionObj) => {
    setScrollPosition(scrollPositionObj)
  }

  const onActiveLink = (value) => {
    window.scrollTo({
      top: scrollPosition[value],
      left: 0,
      behavior: 'smooth',
    })
    setActiveLink(value)
  }

  return (
    <>
      {media.length > 0 && media === 'large' && (
        <HeaderLarge
          scrolled={scrolled}
          activeLink={activeLink}
          onActiveLink={onActiveLink}
          onScrollPosition={onScrollPosition}
        />
      )}
      {media.length > 0 && media === 'small' && (
        <HeaderSmall
          scrolled={scrolled}
          activeLink={activeLink}
          onActiveLink={onActiveLink}
          onScrollPosition={onScrollPosition}
        />
      )}
    </>
  )
}

const HeaderLarge = ({ scrolled, activeLink, onActiveLink, onScrollPosition }) => {
  const scrollPosition = {
    home: 0,
    'about-me': 865,
    skills: 1575,
    projects: 2168,
    contact: 3300,
  }

  useEffect(() => {
    onScrollPosition(scrollPosition)
  }, [])

  return (
    <div className={scrolled ? 'scrolled' : 'header'}>
      <div className="header-logo-container">
        <strong className="header-logo-strong">RITA</strong>
        <strong className="header-logo">PORTFOLIO</strong>
      </div>
      <nav className="header-navbar-container">
        <ul className="header-navbar-ul">
          <li className="header-navbar-li">
            <div
              className={activeLink === 'home' ? 'active header-navbar-link' : 'header-navbar-link'}
              onClick={() => onActiveLink('home')}
            >
              HOME
            </div>
          </li>
          <li className="header-navbar-li">
            <div
              className={
                activeLink === 'about-me' ? 'active header-navbar-link' : 'header-navbar-link'
              }
              onClick={() => onActiveLink('about-me')}
            >
              ABOUT
            </div>
          </li>
          <li className="header-navbar-li">
            <div
              className={
                activeLink === 'skills' ? 'active header-navbar-link' : 'header-navbar-link'
              }
              onClick={() => onActiveLink('skills')}
            >
              SKILLS
            </div>
          </li>
          <li className="header-navbar-li">
            <div
              className={
                activeLink === 'projects' ? 'active header-navbar-link' : 'header-navbar-link'
              }
              onClick={() => onActiveLink('projects')}
            >
              PROJECTS
            </div>
          </li>
        </ul>
        <div className="header-navbar-icons">
          <a
            href="https://gitlab.com/rita7811"
            target="_blank"
            className="header-navbar-icon-link"
            rel="noopener noreferrer"
          >
            <Icon icon="mdi:gitlab" style={{ width: '30px', height: '30px' }} />
          </a>
          <a
            href="https://www.linkedin.com/in/rita-in"
            target="_blank"
            className="header-navbar-icon-link"
            rel="noopener noreferrer"
          >
            <Icon
              icon="entypo-social:linkedin-with-circle"
              style={{ width: '30px', height: '30px' }}
            />
          </a>
        </div>
        <div className="header-navbar-btn-container">
          <button
            className={activeLink === 'contact' ? 'header-navbar-btn active' : 'header-navbar-btn'}
            onClick={() => onActiveLink('contact')}
          >
            Let's Connect
          </button>
        </div>
      </nav>
    </div>
  )
}

const HeaderSmall = ({ scrolled, activeLink, onActiveLink, onScrollPosition }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <div className={scrolled ? 'scrolled' : 'header'}>
      <div className="headerS-position">
        <div className="headerS-logo-container">
          <strong className="headerS-logo-strong">RITA</strong>
          <strong className="headerS-logo">PORTFOLIO</strong>
        </div>
        <div className="headerS-navbar">
          {!menuOpen ? (
            <Icon icon="ep:menu" className="headerS-menu-icon" onClick={() => setMenuOpen(true)} />
          ) : (
            <>
              <Icon
                icon="fa6-regular:circle-xmark"
                className="headerS-menu-icon"
                onClick={() => setMenuOpen(false)}
              />
              {menuOpen && (
                <Menu
                  activeLink={activeLink}
                  onActiveLink={onActiveLink}
                  onScrollPosition={onScrollPosition}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const Menu = ({ activeLink, onActiveLink, onScrollPosition }) => {
  const scrollPosition = {
    home: 0,
    'about-me': 865,
    skills: 1700,
    projects: 2400,
    contact: 3550,
  }

  useEffect(() => {
    onScrollPosition(scrollPosition)
  }, [])

  return (
    <nav className="menu-dropdown-container">
      <ul className="menu-dropdown-ul">
        <li className="menu-dropdown-li">
          <div
            className={activeLink === 'home' ? 'active menu-dropdown-link' : 'menu-dropdown-link'}
            onClick={() => onActiveLink('home')}
          >
            HOME
          </div>
        </li>
        <li className="menu-dropdown-li">
          <div
            className={
              activeLink === 'about-me' ? 'active menu-dropdown-link' : 'menu-dropdown-link'
            }
            onClick={() => onActiveLink('about-me')}
          >
            ABOUT
          </div>
        </li>
        <li className="menu-dropdown-li">
          <div
            className={activeLink === 'skills' ? 'active menu-dropdown-link' : 'menu-dropdown-link'}
            onClick={() => onActiveLink('skills')}
          >
            SKILLS
          </div>
        </li>
        <li className="menu-dropdown-li">
          <div
            className={
              activeLink === 'projects' ? 'active menu-dropdown-link' : 'menu-dropdown-link'
            }
            onClick={() => onActiveLink('projects')}
          >
            PROJECTS
          </div>
        </li>
        <li className="menu-dropdown-li-icons">
          <a
            href="https://gitlab.com/rita7811"
            target="_blank"
            className="menu-dropdown-icon-link"
            rel="noopener noreferrer"
          >
            <Icon icon="mdi:gitlab" style={{ width: '30px', height: '30px' }} />
          </a>
          <a
            href="https://www.linkedin.com/in/rita-in"
            target="_blank"
            className="menu-dropdown-icon-link"
            rel="noopener noreferrer"
          >
            <Icon
              icon="entypo-social:linkedin-with-circle"
              style={{ width: '30px', height: '30px' }}
            />
          </a>
        </li>
        <li className="menu-dropdown-li-btn">
          <button
            className={activeLink === 'contact' ? 'menu-dropdown-btn active' : 'menu-dropdown-btn'}
            onClick={() => onActiveLink('contact')}
          >
            Let's Connect
          </button>
        </li>
      </ul>
    </nav>
  )
}
