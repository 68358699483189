import { useState } from 'react'

export const usePagination = (data = [], pageSize) => {
  const [currentPage, setCurrentPage] = useState(1)
  const maxPage = Math.ceil(data.length / pageSize)

  const currentData = () => {
    const begin = (currentPage - 1) * pageSize
    const end = begin + pageSize
    return data.slice(begin, end)
  }

  const next = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
  }

  const prev = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const jump = (page) => {
    const pageNumber = Math.max(1, page)
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage))
  }

  return { next, prev, jump, currentData, currentPage, maxPage }
}
