import './ProjectCard.scss'

export const ProjectCard = ({ dataType, title, description, fileUrl, selected, onSelected }) => {
  return (
    <div className="projectCard">
      <div className="projectCard-col">
        {dataType === 'video' && (
          <div
            className={
              selected === title ? 'active projectCard-url-container' : 'projectCard-url-container'
            }
          >
            <video
              className="projectCard-video"
              onClick={() => onSelected(title)}
              preload="auto"
              controls
            >
              <source src={fileUrl} type="video/mp4" />
            </video>
          </div>
        )}
        {dataType === 'image' && (
          <div
            className={
              selected === title ? 'active projectCard-url-container' : 'projectCard-url-container'
            }
          >
            <img src="" alt={title} className="projectCard-img" />
          </div>
        )}
        <div className="projectCard-main">
          <h4 className="projectCard-header">{title}</h4>
          <p className="projectCard-description">{description}</p>
        </div>
      </div>
    </div>
  )
}
