import './Banner.scss'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'

export const Banner = ({ media }) => {
  const [loopNum, setLoopNum] = useState(0)
  const [isDeleting, setIsDeleting] = useState(false)
  const toRotate = ['Full-stack Developer', 'Software Developer', 'Web Developer']
  const [text, setText] = useState('')
  const [delta, setDelta] = useState(300 - Math.random() * 100)
  const period = 2000

  useEffect(() => {
    let ticker = setInterval(() => {
      tick()
    }, delta)
    return () => {
      clearInterval(ticker)
    }
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length
    let fullText = toRotate[i]
    let updateText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1)
    setText(updateText)

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2)
    }

    if (!isDeleting && updateText === fullText) {
      setIsDeleting(true)
      setDelta(period)
    } else if (isDeleting && updateText === '') {
      setIsDeleting(false)
      setLoopNum(loopNum + 1)
      setDelta(500)
    }
  }

  return (
    <section id="home" className="banner">
      <div className="banner-container">
        <div className={media && `banner-row-center-${media}`}>
          <div className={media && `banner-col-1-${media}`}>
            <img
              src={require('./../assets/img/banner-Img.webp')}
              alt="banner-img"
              className={media && `banner-img-${media}`}
            />
          </div>
          <div className={media && `banner-col-2-${media}`}>
            <p className="banner-tagline">Welcome to my Portfolio</p>
            <h1 className="banner-title">
              {`Hi, I'm Rita Huang`} <br />
              <span className="banner-wrap">{text}</span>
            </h1>
            <p className="banner-text">
              Rita is a software developer who loves to code ❤ <br />
              Building bridges between ideas and reality with code! <br />
              Let's translate your vision into reality!
            </p>
            <a href="#contact">
              <button className="banner-btn">
                Let's connect
                <Icon icon="tabler:arrow-big-right-lines-filled" className="banner-icon" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
