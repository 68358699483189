import './AppLoading.scss'

export const AppLoading = () => {
  return (
    <div className="loading">
      <div className="loading-flipping-cards">
        <div className="loading-card">W</div>
        <div className="loading-card">e</div>
        <div className="loading-card">l</div>
        <div className="loading-card">c</div>
        <div className="loading-card">o</div>
        <div className="loading-card">m</div>
        <div className="loading-card">e</div>
      </div>
    </div>
  )
}
