import './Contact.scss'
import { useState } from 'react'
import { SECTIONS } from '../assets/helper'
import { getContact } from '../api'

export const Contact = ({ media }) => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails)
  const [buttonText, setButtonText] = useState('Send Message')
  const [status, setStatus] = useState({})
  const [isValidEmail, setIsValidEmail] = useState(true)

  const handleInputUpdate = (category, value) => {
    if (category === 'email') {
      const newEmail = value
      const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      setIsValidEmail(isValid.test(newEmail))
      setFormDetails({ ...formDetails, email: newEmail })
    }

    setFormDetails({ ...formDetails, [category]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setButtonText('Sending...')
    try {
      let response = await getContact({ formDetails })
      if (response === 200) {
        setButtonText('Sent')
        setFormDetails(formInitialDetails)
        setStatus({ success: true, message: 'Message sent successfully!' })
      } else {
        setButtonText('Send Message again')
        setStatus({ success: false, message: 'Something went wrong, please try again later.' })
      }
    } catch (error) {
      setButtonText('Send Message again')
      setStatus({ success: false, message: 'Something went wrong, please try again later.' })
    } finally {
      // console.log('finally!!!')
    }
  }

  return (
    <section className="contact" id="contact">
      <div className="contact-container">
        <div className="contact-row-1">
          <div className="contact-col">
            <h2 className="section_header">{SECTIONS && SECTIONS.contact.header}</h2>
            <p className="section_description">{SECTIONS && SECTIONS.contact.description}</p>
          </div>
        </div>
        <div className={media && `contact-row-2-${media}`}>
          <div className="contact-col-1">
            <img
              src={require('./../assets/img/contact.webp')}
              alt="contact-me"
              className="contact-img"
            />
          </div>
          <div className="contact-col-2">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="contact-form-row">
                <div className="contact-form-col">
                  <label htmlFor="firstName" className="contact-label">
                    Your First Name
                  </label>
                  <input
                    type="text"
                    className="contact-input"
                    id="firstName"
                    value={formDetails.firstName}
                    placeholder="First Name"
                    onChange={(e) => handleInputUpdate('firstName', e.target.value)}
                    required
                  />
                </div>
                <div className="contact-form-col">
                  <label htmlFor="lastName" className="contact-label">
                    Your Last Name
                  </label>
                  <input
                    type="text"
                    className="contact-input"
                    id="lastName"
                    value={formDetails.lastName}
                    placeholder="Last Name"
                    onChange={(e) => handleInputUpdate('lastName', e.target.value)}
                    required
                  />
                </div>
                <div className="contact-form-col">
                  <label htmlFor="email" className="contact-label">
                    Your Email
                  </label>
                  <input
                    type="email"
                    className={isValidEmail ? 'contact-input' : 'contact-input-error'}
                    id="email"
                    value={formDetails.email}
                    placeholder="Email"
                    onChange={(e) => handleInputUpdate('email', e.target.value)}
                    required
                  />
                  {!isValidEmail && (
                    <>
                      {formDetails.email.length > 0 ? (
                        <p className="contact-input-validation">
                          Please enter an email address in the format xxx@yyy.zzz
                        </p>
                      ) : (
                        <p className="contact-input-validation">Please enter your email address.</p>
                      )}
                    </>
                  )}
                </div>
                <div className="contact-form-col">
                  <label htmlFor="subject" className="contact-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="contact-input"
                    id="subject"
                    value={formDetails.subject}
                    placeholder="Subject"
                    onChange={(e) => handleInputUpdate('subject', e.target.value)}
                    required
                  />
                </div>
                <div className="contact-form-col">
                  <label htmlFor="message" className="contact-label">
                    Your Message
                  </label>
                  <textarea
                    className="contact-text"
                    rows={6}
                    id="message"
                    value={formDetails.message}
                    placeholder="Let's talk about ..."
                    onChange={(e) => handleInputUpdate('message', e.target.value)}
                    required
                  />
                  <button className="contact-form-btn">
                    <span className="contact-form-btn-text">{buttonText}</span>
                  </button>
                </div>
                {status.message && (
                  <div className="contact-form-col">
                    <p
                      className={
                        status.success === false ? 'contact-form-danger' : 'contact-form-success'
                      }
                    >
                      {status.message}
                    </p>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
