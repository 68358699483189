import axios from 'axios'
import { config } from '../config'

const apiClient = axios.create({
  baseURL: config.API_BASE_URL,
})

export const getVideo = async ({ key, content_type = 'mp4' }) => {
  const response = await apiClient.post(`${config.API_GET_VIDEO}`, {
    key: key,
    content_type: content_type,
  })
  return response
}

export const getContact = async ({ formDetails }) => {
  const response = await apiClient.post(`${config.API_GET_CONTACT}`, formDetails, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data.code
}
