import './AboutMe.scss'
import { useState, useTransition } from 'react'
import { TabBtn } from './shared/TabBtn'
import { ABOUT_ME_DATA, SECTIONS } from '../assets/helper'

export const AboutMe = ({ media }) => {
  const [tab, setTab] = useState('education')
  const [isPending, startTransition] = useTransition()

  const handleTabChange = (id) => {
    startTransition(() => {
      setTab(id)
    })
  }

  return (
    <section className="aboutMe" id="about-me">
      <div className="aboutMe-container">
        <div className={media && `aboutMe-row-center-${media}`}>
          <div className="aboutMe-col-1">
            <h2 className="aboutMe-header">{SECTIONS && SECTIONS.aboutMe.header}</h2>
            <p className="aboutMe-desc">{SECTIONS && SECTIONS.aboutMe.description}</p>
            <div className="aboutMe-tab-container">
              <TabBtn isActive={tab === 'education'} selectTab={() => handleTabChange('education')}>
                Education
              </TabBtn>
              <TabBtn
                isActive={tab === 'certification'}
                selectTab={() => handleTabChange('certification')}
              >
                Certification
              </TabBtn>
              <TabBtn
                isActive={tab === 'experience'}
                selectTab={() => handleTabChange('experience')}
              >
                Experience
              </TabBtn>
            </div>
            <div className="aboutMe-tab-info-container">
              {ABOUT_ME_DATA.find((t) => t.id === tab).content}
            </div>
          </div>

          <div className="aboutMe-col-2">
            <img
              src={require('./../assets/img/about-me.webp')}
              alt="aboutMe-img"
              className="aboutMe-img"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
