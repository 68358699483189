import './TabBtn.scss'

export const TabBtn = ({ isActive, selectTab, children }) => {
  const buttonClasses = isActive ? 'tabBtn-active' : ''

  return (
    <button className="tabBtn" onClick={selectTab}>
      <p className={`tabBtn-info ${buttonClasses}`}>{children}</p>
    </button>
  )
}
