import './Skills.scss'
import { Icon } from '@iconify/react'
import { SECTIONS } from '../assets/helper'

export const Skills = () => {
  return (
    <section id="skills" className="skills">
      <div className="skills-container">
        <div className="skills-row">
          <h2 className="section_header">{SECTIONS.skills.header}</h2>
          <p className="section_description">{SECTIONS.skills.description}</p>
        </div>
        <div className="skills-row">
          <div className="skills-box">
            <Icon icon="skill-icons:react-dark" className="skills-icon" />
            <Icon icon="skill-icons:nodejs-dark" className="skills-icon" />
            <Icon icon="skill-icons:javascript" className="skills-icon" />
            <Icon icon="skill-icons:typescript" className="skills-icon" />
            <Icon icon="skill-icons:html" className="skills-icon" />
            <Icon icon="skill-icons:css" className="skills-icon" />
            <Icon icon="skill-icons:sass" className="skills-icon" />
            <Icon icon="skill-icons:mysql-light" className="skills-icon" />
            <Icon icon="skill-icons:gcp-light" className="skills-icon" />
            <Icon icon="skill-icons:aws-dark" className="skills-icon" />
            <Icon icon="skill-icons:azure-light" className="skills-icon" />
            <Icon icon="skill-icons:git" className="skills-icon" />
            <Icon icon="skill-icons:gitlab-light" className="skills-icon" />
            <Icon icon="skill-icons:github-light" className="skills-icon" />
            <Icon icon="skill-icons:postman" className="skills-icon" />
            <Icon icon="devicon:webstorm" className="skills-icon" />
            <Icon icon="devicon:datagrip" className="skills-icon" />
            <Icon icon="skill-icons:vscode-light" className="skills-icon" />
          </div>
        </div>
      </div>
    </section>
  )
}
