import './App.scss'
import { Header } from './components/shared/Header'
import { Footer } from './components/shared/Footer'
import React, { useEffect, useState } from 'react'
import { AppLoading } from './components/shared/AppLoading'
import { Skills } from './components/Skills'
import { Banner } from './components/Banner'
import { Projects } from './components/Projects'
import { AboutMe } from './components/AboutMe'
import { Contact } from './components/Contact'
import MediaQuery from 'react-responsive'

export const App = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const time = setTimeout(() => {
      setIsLoading(false)
    }, 2800)
    return () => {
      clearTimeout(time)
    }
  }, [])

  return (
    <>
      {isLoading && (
        <div className="app-loading">
          <AppLoading />
        </div>
      )}
      {!isLoading && (
        <div className="app">
          <MediaQuery minWidth={1224}>
            <Header media="large" />
            <Banner media="large" />
            <AboutMe media="large" />
            <Skills />
            <Projects />
            <Contact media="large" />
            <Footer />
          </MediaQuery>
          <MediaQuery minWidth={992} maxWidth={1223}>
            <Header media="small" />
            <Banner media="medium" />
            <AboutMe media="medium" />
            <Skills />
            <Projects />
            <Contact media="large" />
            <Footer />
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <Header media="small" />
            <Banner media="small" />
            <AboutMe media="small" />
            <Skills />
            <Projects />
            <Contact media="small" />
            <Footer />
          </MediaQuery>
        </div>
      )}
    </>
  )
}
